import Link from "next/link";

const fontSize = {
    fontSize: '14px'
}

const FooterLink = ({ link, className = "" }) => {
    return (
        <li style={fontSize} className={`${className} pointer mb-3`}>
            {
                link.download ? (
                    <a href={link.href} download>{link.name}</a>
                ) : (
                    <Link style={fontSize} href={link.href}>
                            {link.name}
                    </Link>
                )
            }
        </li>
    );
};

export default FooterLink;
